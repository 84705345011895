<template>
    <div class="flex flex-col h-screen">
        <div class="flex flex-1">
            <div id="map" class="flex-1"></div>
            <div id="sidebar" class="w-64 bg-black p-2 no-scrollbar">
                <h2 class="text-lg mb-2 text-white">Extracted Frames</h2>
                <div v-if="!done" class="mt-2 mb-2">
                    <div class="w-full h-1 bg-neutral-700">
                        <div :style="{ width: progress + '%' }"
                            class="h-full bg-primary transition-all ease-out duration-150 rounded"></div>
                    </div>
                </div>
                <button v-if="done" @click="uploadAllFrames"
                    class="mb-2 bg-primary text-white p-1 rounded-md text-sm">
                    Upload
                </button>
                <div id="gallery" class="flex flex-wrap gap-2 bg-black">
                    <div v-for="frame in thumbnails" :key="frame.filename" class="w-24 h-auto overflow-hidden">
                        <img :src="frame.url" :alt="frame.filename" class="w-full h-full object-cover">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';
import piexif from 'piexifjs';
import Header from './Header.vue';
import { parseXMPMetadata, convertDMSToDD, convertToDMS, base64ToBlob, readCSVFile, generateExifData ,getExifData, calculateImageCorners } from '@/assets/js/utils';
import threebox from "threebox-plugin/dist/threebox";


export default {
    name: 'Map',
    props: ['videoFile', 'flightLogFile', 'imageSequenceFiles'],
    components: {
        Header,
    },
    data() {
        return {
            map: null,
            extractedFrames: [],
            thumbnails: [],
            lastFramePosition: null, 
            done: false,
            progress: 0,
            imageObservations: [],
            drone: null,
            cameraLines: [],
            pathLines: [],
            clock: new THREE.Clock(),  
            mixer: null,
        };
    },
    mounted() {
        if (this.videoFile && this.flightLogFile) {
            this.initializeMapForVideo();
        } else if (this.imageSequenceFiles && this.imageSequenceFiles.length > 0) {
            this.initializeMapForImageSequence();
        }
    },
    methods: {
        initializeMap() {
            mapboxgl.accessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            return new mapboxgl.Map({
                container: 'map',
                interactive: true,
                style: 'mapbox://styles/drewjgray3d/clxpeyjmp00gz01ob36sgefis',
                center: [0, 0],
                zoom: 1,
                antialias: true,
            });
        },
        calculateExtents(observations) {
            let top = -Infinity, bottom = Infinity, left = Infinity, right = -Infinity;
            observations.forEach(obs => {
                if (obs.longitude > top) top = obs.longitude;
                if (obs.longitude < bottom) bottom = obs.longitude;
                if (obs.latitude < left) left = obs.latitude;
                if (obs.latitude > right) right = obs.latitude;
            });
            return { top, bottom, left, right };
        },
        addMarker(observation, map) {
            const iconElement = document.createElement('div');
            iconElement.className = 'mdi mdi-image';
            iconElement.style.fontSize = '18px';
            iconElement.style.color = 'white';

            const marker = new mapboxgl.Marker({ element: iconElement })
                .setLngLat([observation.longitude, observation.latitude])
                .addTo(map);

            return marker;
        },
        async initializeMapForImageSequence() {
            let imageObservations = [];
            for (const file of this.imageSequenceFiles) {
                const exifData = await getExifData(file, parseXMPMetadata, convertDMSToDD);
                if (exifData && exifData.latitude && exifData.longitude) {
                    const observation = {
                        latitude: exifData.latitude,
                        longitude: exifData.longitude,
                        altitude: exifData.altitude,
                        bearing: exifData.yaw,
                        width: exifData.width,
                        height: exifData.height,
                        fov: exifData.fov,
                        datetime: exifData.datetime,
                    };
                    imageObservations.push(observation);
                }
            }

            const { top, bottom, left, right } = this.calculateExtents(imageObservations);

            const map = this.initializeMap();

            map.on('style.load', async () => {

                map.fitBounds([
                    [top, left],
                    [bottom, right],
                ], {
                    padding: 50,
                    duration: 2500,
                });

                map.addSource('dronePath', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: imageObservations.map(o => [o.longitude, o.latitude]),
                        },
                    },
                });

                map.addLayer({
                    id: 'droneOutline',
                    type: 'line',
                    source: 'dronePath',
                    layout: {},
                    paint: {
                        'line-color': '#27BDF4',
                        'line-width': 3,
                    },
                });

                map.once('moveend', async () => {
                    let index = 0;
                    const markers = [];
                    const interval = setInterval(() => {
                        if (index >= this.imageSequenceFiles.length) {
                            clearInterval(interval);
                            this.addFramesToMap(map);
                            this.done = true;
                            markers.forEach(marker => marker.remove());
                            return;
                        }

                        this.progress = (index / this.imageSequenceFiles.length) * 100;
                        const file = this.imageSequenceFiles[index];
                        const observation = imageObservations[index];

                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const url = e.target.result;
                            const blob = base64ToBlob(url);
                            this.extractedFrames.push({
                                blob: blob,
                                filename: file.name,
                                corners: calculateImageCorners(observation).slice(1, 5),
                                url: URL.createObjectURL(blob),
                            });
                        };
                        reader.readAsDataURL(file);
                        const marker = this.addMarker(observation, map);
                        markers.push(marker);

                        index++;
                    }, 400);
                });
            });
        },
        async initializeMapForVideo() {
            const observations = await readCSVFile(this.flightLogFile);
            const frequency  = 1000 / (observations[1]['time(millisecond)'] - observations[0]['time(millisecond)']);
            let videoObservations = [];
            observations.forEach(o => {
                if (o.isVideo == '0') return;
                if (isNaN(o.latitude) || isNaN(o.longitude)) return;
                const obs = {
                    latitude: parseFloat(o.latitude),
                    longitude: parseFloat(o.longitude),
                    altitude: o["ascent(feet)"] * 0.3048,
                    bearing: o["compass_heading(degrees)"],
                    roll: o["roll(degrees)"],
                    pitch: o["pitch(degrees)"],
                    fov: 58.9, // while shooting video
                    datetime: o["datetime(utc)"],
                    absolute_altitude: o["altitude_above_seaLevel(feet)"] * 0.3048,
                };
                videoObservations.push(obs);
            });

            const { top, bottom, left, right } = this.calculateExtents(videoObservations);

            const map = this.initializeMap();

            map.on('style.load', () => {
                if (map.getLayer("custom_layer") == null) {
                    map.addLayer({
                        id: "custom_layer",
                        type: "custom",
                        renderingMode: "3d",

                        onAdd: (map, mbxContext) => {
                            window.tb = new Threebox(map, map.getCanvas().getContext("webgl"), {defaultLights: false});
                            const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
                            directionalLight.position.set(10, 10, 10);  
                            window.tb.scene.add(directionalLight);
                            const ambientLight = new THREE.AmbientLight(0xffffff, 2);
                            window.tb.scene.add(ambientLight);
                            
                            const loader = new THREE.GLTFLoader();
                            loader.load(
                                'models/drone2.glb',
                                 (gltf) => {
                                    const model = gltf.scene;
                                     model.traverse((object) => {
                                         if (object.isMesh) {
                                             object.material.color.setRGB(1, 1, 1);
                                         }
                                     });
                                    model.position.set(0, 0.13, 0);

                                    this.mixer = new THREE.AnimationMixer(model);
                                    const action = this.mixer.clipAction(gltf.animations[1]);
                                    action.play();
                                    
                                    const threeboxObject = tb.Object3D({ obj: model, units: 'meters', anchor: 'center' });
                                    this.drone = threeboxObject;
                                    tb.add(this.drone);
                                },
                                undefined, 
                                function (error) {
                                    console.error('Error loading model:', error);
                                }
                            );
                        },
                        render: (gl, matrix) => {
                            if (this.mixer) this.mixer.update(this.clock.getDelta())
                            tb.update();
                        },
                    });
                }

                this.add3DFlightPath(map, videoObservations);


                map.addSource('video', {
                    type: 'video',
                    urls: [URL.createObjectURL(this.videoFile)],
                    coordinates: [
                        [(top + bottom) / 2 + 0.0007, (left + right) / 2 - 0.0007],
                        [(top + bottom) / 2 + 0.0007, (left + right) / 2 + 0.0007],
                        [(top + bottom) / 2 - 0.0007, (left + right) / 2 + 0.0007],
                        [(top + bottom) / 2 - 0.0007, (left + right) / 2 - 0.0007],
                    ],
                });

                map.addLayer({
                    id: 'video',
                    type: 'raster',
                    source: 'video',
                });

                map.fitBounds([
                    [top, left],
                    [bottom, right],
                ], {
                    padding: 50,
                    duration: 2500
                });
              

                // Flight Loop -----------------------------------------------------
                const videoSource = map.getSource('video');
                var lastTime = null;
                const markers = [];
                const detectFrame = () => {
                    const animationFrameId = requestAnimationFrame(detectFrame);

                    const video = videoSource.video;
                    if (!video || !video.videoWidth) return;

                    const {
                        videoWidth,
                        videoHeight,
                        currentTime,
                        duration
                    } = video;

                    video.playbackRate = 2.0;

                    if (!lastTime) lastTime = currentTime;
                    this.progress = (currentTime / duration) * 100;
                    if (currentTime < lastTime) {
                        this.done = true;
                        cancelAnimationFrame(animationFrameId);
                        this.addFramesToMap(map);
                        this.cleanUp(map, markers)
                        return;
                    }

                    const frame = Math.floor(currentTime * frequency);  
                    const observation = videoObservations[frame % videoObservations.length];
                    observation.width = videoWidth;
                    observation.height = videoHeight;

                    const [distance, topRight, bottomRight, bottomLeft, topLeft] = calculateImageCorners(observation);
                    
                    videoSource.setCoordinates([
                        topRight,
                        bottomRight,
                        bottomLeft,
                        topLeft,
                    ]);

                    this.animateDrone(observation, [topRight, bottomRight, bottomLeft, topLeft]);

                    this.extractFrame(map, markers, video, frame, observation, distance, topLeft, topRight, bottomRight, bottomLeft);
                    lastTime = currentTime;
                };

                detectFrame();
            });
        },
        cleanUp(map, markers) {
            map.removeLayer('video');
            map.removeSource('video');
            markers.forEach(marker => marker.remove());
            this.cameraLines.forEach(line => {
                if (line && window.tb) {
                    window.tb.remove(line);
                }
            });
            this.cameraLines = [];
            this.pathLines.forEach(line => {
                if (line && window.tb) {
                    window.tb.remove(line);
                }
            });
            this.pathLines = [];
            window.tb.remove(this.drone);
            this.drone = null;
        },
        animateDrone(observation, corners) {
            if (this.drone) {
                this.drone.setCoords([observation.longitude, observation.latitude, observation.absolute_altitude]);
                this.drone.scale.set(2, 2, 2);

                // Assume the drone model is initially oriented to face 'north' in its model definition
                this.drone.rotation.order = 'ZXY';
                this.drone.rotation.set(
                    Math.PI / 2 - observation.pitch * Math.PI / 180,
                    observation.roll * Math.PI / 180,
                    -observation.bearing * Math.PI / 180
                );

                if (this.cameraLines) {
                    this.cameraLines.forEach(line => {
                        window.tb.remove(line); 
                    });
                    this.cameraLines = []; 
                }

                corners.map(corner => {
                    const start = window.tb.projectToWorld([observation.longitude, observation.latitude, observation.absolute_altitude]);
                    const end = window.tb.projectToWorld([corner[0], corner[1], observation.absolute_altitude-observation.altitude]); // Assuming altitude is 0 for image corners

                    const geometry = new THREE.BufferGeometry().setFromPoints([
                        new THREE.Vector3(start.x, start.y, start.z),
                        new THREE.Vector3(end.x, end.y, end.z)
                    ]);

                    const material = new THREE.LineBasicMaterial({
                        color: 'white',  
                    });

                    const line = new THREE.Line(geometry, material);
                    this.cameraLines.push(line);
                    window.tb.add(line);
                });
            }
        },
        add3DFlightPath(map, videoObservations) {
            this.pathLines.forEach(line => {
                if (line && window.tb) {
                    window.tb.remove(line);
                }
            });
            this.pathLines = [];

            // Draw the 3D path of the drone
            videoObservations.forEach((obs, index) => {
                if (index === 0) return; 

                const line_segment = window.tb.line({
                    geometry: [
                        [videoObservations[index - 1].longitude, videoObservations[index - 1].latitude, videoObservations[index - 1].absolute_altitude],
                        [obs.longitude, obs.latitude, obs.absolute_altitude]
                    ],
                    color: '#27bdf4', 
                    width: 1,
                    opacity: 1
                });
                window.tb.add(line_segment);
                this.pathLines.push(line_segment);

                // Add vertical lines
                if (index % 10 !== 0) return; 
                const line_vertical = window.tb.line({
                    geometry: [
                        [videoObservations[index - 1].longitude, videoObservations[index - 1].latitude, 0], // Start at ground level
                        [videoObservations[index - 1].longitude, videoObservations[index - 1].latitude, videoObservations[index - 1].absolute_altitude] // End at the altitude of the previous point
                    ],
                    color: '#27bdf4',  
                    width: 1,
                    opacity: 0.1
                });
                window.tb.add(line_vertical);
                this.pathLines.push(line_vertical);
            });

            

            map.addSource('dronePath', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [videoObservations.map(o => [o.longitude, o.latitude])],
                    },
                },
            });

            map.addLayer({
                id: 'droneOutline',
                type: 'line',
                source: 'dronePath',
                layout: {},
                paint: {
                    'line-color': '#333333',
                    'line-width': 3,
                },
            });
        },
        extractFrame(map, markers, video, frame, observation, distance, topLeft, topRight, bottomRight, bottomLeft) {
            const frameDistance = distance * Math.tan(59 * Math.PI / 360);

            if (this.lastFramePosition) {
                const lastCenter = turf.point([this.lastFramePosition.longitude, this.lastFramePosition.latitude]);
                const currentCenter = turf.point([observation.longitude, observation.latitude]);
                const movedDistance = turf.distance(lastCenter, currentCenter, { units: 'meters' });

                if (movedDistance >= frameDistance / 2) {
                    // const marker = this.addMarker(observation, map);
                    // markers.push(marker);

                    this.saveFrame(video, frame, observation, topLeft, topRight, bottomRight, bottomLeft);
                    this.lastFramePosition = { longitude: observation.longitude, latitude: observation.latitude };
                }
            } else {
                this.lastFramePosition = { longitude: observation.longitude, latitude: observation.latitude };
            }
        },
        async saveFrame(video, frame, observation, topLeft, topRight, bottomRight, bottomLeft) {
            // var canvas = document.createElement('canvas');
            // canvas.width = video.videoWidth;
            // canvas.height = video.videoHeight;
            // var ctx = canvas.getContext('2d');
            // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convert canvas to full-size image Blob
            // const fullSizeBlob = await new Promise((resolve) => {
            //     canvas.toBlob((blob) => {
            //         resolve(blob);
            //     }, 'image/jpeg');
            // });

            // const fullSizeUrl = URL.createObjectURL(fullSizeBlob);

            // Read and insert EXIF data
            // const base64Data = await new Promise((resolve, reject) => {
            //     const reader = new FileReader();
            //     reader.onload = (e) => resolve(e.target.result);
            //     reader.onerror = (e) => reject(e);
            //     reader.readAsDataURL(fullSizeBlob);
            // });

            // const exifData = generateExifData(observation.datetime, observation.latitude, observation.longitude, observation.altitude);
            // const exifBytes = piexif.dump(exifData);
            // const newBase64Data = piexif.insert(exifBytes, base64Data);
            // const newFullSizeBlob = base64ToBlob(newBase64Data);
            // const newFullSizeUrl = URL.createObjectURL(newFullSizeBlob);

            // Create Image object for thumbnail
            // var image = new Image();
            // image.src = canvas.toDataURL('image/jpeg');
            // await new Promise((resolve) => (image.onload = resolve));

            // Compress image to thumbnail
            // var thumbnailBlob = await this.compressImage(image, 100, 100);
            // var thumbnailUrl = URL.createObjectURL(thumbnailBlob);

            // Save full-size image and thumbnail
            // this.extractedFrames.push({
            //     fullSizeBlob: newFullSizeBlob,
            //     filename: `frame_${frame}.jpg`,
            //     url: newFullSizeUrl,
            //     corners: [topRight, bottomRight, bottomLeft, topLeft]
            // });

            // this.thumbnails.push({
            //     filename: `frame_${frame}.jpg`,
            //     url: thumbnailUrl,
            // });

            // canvas.remove();
            // image.remove();

            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            // URL.revokeObjectURL(thumbnailUrl);
            // canvas.width = 0;
            // canvas.height = 0;
            // canvas = null;
            // image.src = '';
            // image = null;
            // ctx = null;

            // if (window.gc) {
            //     window.gc();
            // }
            
            
        },
        async downloadAllFrames() {
            for (const { blob, filename } of this.extractedFrames) {
                await this.downloadFrame(blob, filename);
            }
        },
        async compressImage(image, maxWidth, maxHeight) {
            return new Promise((resolve) => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = image.width;
                let height = image.height;

                if (width > maxWidth || height > maxHeight) {
                    if (width > height) {
                        height = Math.round((maxHeight / width) * height);
                        width = maxWidth;
                    } else {
                        width = Math.round((maxWidth / height) * width);
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 0.7); 
            });
        },
        downloadFrame(blob, filename) {
            return new Promise((resolve) => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(resolve, 200); // 200ms delay to avoid browser limitations
            });
        },
        addFramesToMap(map) {
            this.extractedFrames.forEach(frame => {
                const imageSourceId = `imageSource_${frame.filename}`;
                map.addSource(imageSourceId, {
                    type: 'image',
                    url: frame.url,
                    coordinates: frame.corners,
                });
                map.addLayer({
                    id: `imageLayer_${frame.filename}`,
                    type: 'raster',
                    source: imageSourceId,
                });

            });
        },
        async uploadAllFrames() {
            this.done = false;
            const timestamp = this.generateTimestamp(); 
            let totalFrames = this.extractedFrames.length; 

            for (let index = 0; index < totalFrames; index++) {
                const frame = this.extractedFrames[0]; // frames are being removed so grab first frame
                await this.uploadFrame(frame, timestamp); 
                this.extractedFrames.splice(0, 1); // Remove the frame after uploading
                this.progress = ((index + 1) / totalFrames) * 100; 
            }
            this.done = true; 
        },

        generateTimestamp() {
            const date = new Date();
            return `${date.getFullYear()}_${(date.getMonth() + 1).toString().padStart(2, '0')}_${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}_${date.getMinutes().toString().padStart(2, '0')}_${date.getSeconds().toString().padStart(2, '0')}`;
        },
        async uploadFrame(frame, timestamp) {
            const base64Data = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split('base64,')[1]);
                reader.onerror = error => reject(error);
                reader.readAsDataURL(frame.blob);
            });

            const payload = {
                file: base64Data,
                filename: frame.filename,
                timestamp: timestamp 
            };

            try {
                const response = await fetch('https://us-central1-cg-drone-map.cloudfunctions.net/uploadFrameToGCS', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to upload frame');
                }

                const data = await response.json();
                console.log('Uploaded frame URL:', data.url);
            } catch (error) {
                console.error('Error uploading frame:', error);
            }
        },
    },
};
</script>

<style scoped>
#map {
    width: 100vw;
    height: 100vh;
}

#sidebar {
    width: 350px;
    height: calc(100vh - 16px);
    overflow-y: scroll;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
}

#gallery img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}
</style>
