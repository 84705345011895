<template>
    <div class="flex justify-between w-full bg-black text-white p-2 border-b border-neutral-700">
        <div class="flex items-center">
            <!-- Company Logo -->
            <img src="@/assets/images/cg.png" alt="Company Logo" class="h-8 w-8 mr-2 ml-1">

            <div class="inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
            </div>
            <div class="inline-flex items-center font-normal">
                ReefOS Drone Map
            </div>
        </div>
        <button @click="startOver" v-if="$route.path != '/'" class="bg-neutral-700 text-white px-3 py-1 rounded-md">New Map</button>
    </div>
</template>

<script>
export default {
    name: 'Header',
    methods: {
        startOver() {
            this.$router.push('/');
        }
    }
}
</script>
