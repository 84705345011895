import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC-eykQQ6M-NTy6NAE_QvrZj9aOkTiatcM",
  authDomain: "cg-drone-map.firebaseapp.com",
  projectId: "cg-drone-map",
  storageBucket: "cg-drone-map.appspot.com",
  messagingSenderId: "715330783589",
  appId: "1:715330783589:web:c6db47454c05cf0791b98c",
  measurementId: "G-T99MWJ99H9"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
