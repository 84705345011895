<template>
	<div class="h-screen flex items-center justify-center bg-black text-white">
		<div id="dropContainer" class="w-full flex items-center justify-center">
			<div class="py-4 px-4 rounded-md shadow-xl outline outline-1 outline-neutral-700 outline-offset-4">
				<div class="flex space-x-4">
					<div v-if="videoFile" class="bg-primary rounded-md shadow-lg text-center p-4 flex-1">
						<h3>Video Added</h3>
						<i class="mdi mdi-check-circle text-xl mt-4 mb-2"></i>
					</div>
					<div v-else id="videoButton"
						class="bg-neutral-700 rounded-md shadow-lg text-center p-4 flex-1 hover:bg-neutral-600 cursor-pointer"
						@click="selectVideo">
						<h3>Add Drone Video (.mp4)</h3>
						<!-- <i class="mdi mdi-video-image text-xl mt-4 mb-2"></i> -->
					</div>
					<div v-if="flightLogFile" class="bg-primary rounded-md shadow-lg text-center p-4 flex-1">
						<h3>Flight Log Added</h3>
						<i class="mdi mdi-check-circle text-xl mt-4 mb-2"></i>
					</div>
					<div v-else id="flightLogButton"
						class="bg-neutral-700 rounded-md shadow-lg text-center p-4 flex-1 hover:bg-neutral-600 cursor-pointer"
						@click="selectFlightLog">
						<h3>Add Airdata Log (.csv)</h3>
						<!-- <i class="mdi mdi-file-delimited text-xl mt-4 mb-2"></i> -->
					</div>
					<div v-if="videoFile && flightLogFile"
						class="bg-neutral-700 rounded-md shadow-lg text-center p-4 flex-1 hover:bg-neutral-600 cursor-pointer"
						@click="start">
						<h3>Start</h3>
						<!-- <i class="mdi mdi-file-delimited text-xl mt-4 mb-2"></i> -->
					</div>
				</div>

				<!-- Image Sequence -->
				<div class="flex flex-col items-center mt-4">
					<h3 class="text-lg">OR</h3>
					<div class="flex space-x-4 mt-4">
						<div id="imageSequenceButton"
							class="bg-neutral-700 rounded-md shadow-lg text-center p-4 flex-1 hover:bg-neutral-600 cursor-pointer"
							@click="selectImageSequence">
							<h3>Add Image Sequence (.jpg)</h3>
							<!-- <i class="mdi mdi-image-multiple text-xl mt-4 mb-2"></i> -->
						</div>
						<div v-if="imageSequenceFiles.length > 0"
							class="bg-neutral-700 rounded-md shadow-lg text-center p-4 flex-1 hover:bg-neutral-600 cursor-pointer"
							@click="startWithImages">
							<h3>Start</h3>
							<!-- <i class="mdi mdi-file-delimited text-xl mt-4 mb-2"></i> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="hidden">
			<input id="videoInput" type="file" accept="video/*" ref="videoInput" @change="handleVideoChange">
			<input id="flightLogInput" type="file" accept="text/csv, application/csv" ref="flightLogInput"
				@change="handleFlightLogChange">
			<!-- Add input for image sequence -->
			<input id="imageSequenceInput" type="file" accept="image/jpeg" ref="imageSequenceInput" multiple
				@change="handleImageSequenceChange">
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileDrop',
	data() {
		return {
			videoFile: null,
			flightLogFile: null,
			imageSequenceFiles: []
		};
	},
	methods: {
		selectVideo() {
			this.$refs.videoInput.click();
		},
		handleVideoChange(event) {
			this.videoFile = event.target.files[0];
		},
		selectFlightLog() {
			this.$refs.flightLogInput.click();
		},
		handleFlightLogChange(event) {
			this.flightLogFile = event.target.files[0];
		},
		selectImageSequence() {
			this.$refs.imageSequenceInput.click();
		},
		handleImageSequenceChange(event) {
			this.imageSequenceFiles = Array.from(event.target.files);
		},
		start() {
			if (this.videoFile && this.flightLogFile) {
				const files = {
					videoFile: this.videoFile,
					flightLogFile: this.flightLogFile,
				};
				this.$router.push({ name: 'map', params: files });
			}
		},
		startWithImages() {
			if (this.imageSequenceFiles.length > 0) {
				const files = {
					imageSequenceFiles: this.imageSequenceFiles,
				};
				this.$router.push({ name: 'map', params: files });
			}
		}
	}
};
</script>

<style scoped>
/* Add your scoped styles here if needed */
</style>
