import piexif from 'piexifjs';
import * as turf from '@turf/turf';
import Papa from 'papaparse';

export function parseXMPMetadata(base64String) {
    const xmpData = atob(base64String).match(/<x:xmpmeta[\s\S]+<\/x:xmpmeta>/i);
    if (!xmpData) return null;
    const xmlString = xmpData[0];

    const extractValueWithNamespace = (namespace, tag) => {
        const regex = new RegExp(`${namespace}:${tag}="([^"]*)"`, 'i');
        const match = xmlString.match(regex);
        return match ? match[1] : null;
    };

    const relativeAltitude = extractValueWithNamespace("drone-dji", "RelativeAltitude");
    const yaw = extractValueWithNamespace("drone-dji", "FlightYawDegree");

    return {
        relativeAltitude: parseFloat(relativeAltitude),
        yaw: parseFloat(yaw),
    };
}

export function convertDMSToDD(dms, ref) {
    const [degrees, minutes, seconds] = dms;
    let dd = degrees[0] + minutes[0] / 60 + seconds[0] / seconds[1] / 3600;
    if (ref === 'S' || ref === 'W') dd = -dd;
    return dd;
}

export function convertToDMS(deg) {
    const absDeg = Math.abs(deg);
    const d = Math.floor(absDeg);
    const minFloat = (absDeg - d) * 60;
    const m = Math.floor(minFloat);
    const secFloat = (minFloat - m) * 60;
    const s = Math.round(secFloat * 100);
    return [[d, 1], [m, 1], [s, 100]];
}

export function base64ToBlob(base64) {
    const binary = atob(base64.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

export function readCSVFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const results = Papa.parse(text, {
                header: true,
                transformHeader: (h) => h.trim(),
            });
            resolve(results.data);
        };
        reader.readAsText(file);
    });
}

export function generateExifData(datetime, latitude, longitude, altitude) {
    var zeroth = {};
    var exif = {};
    var gps = {};

    zeroth[piexif.ImageIFD.Software] = "ReefOS Drone Map";
    exif[piexif.ExifIFD.DateTimeOriginal] = datetime.replace(/-/g, ':');
    gps[piexif.GPSIFD.GPSLatitudeRef] = latitude >= 0 ? 'N' : 'S';
    gps[piexif.GPSIFD.GPSLatitude] = convertToDMS(latitude);
    gps[piexif.GPSIFD.GPSLongitudeRef] = longitude >= 0 ? 'E' : 'W';
    gps[piexif.GPSIFD.GPSLongitude] = convertToDMS(longitude);
    gps[piexif.GPSIFD.GPSAltitudeRef] = altitude >= 0 ? 0 : 1;
    gps[piexif.GPSIFD.GPSAltitude] = [Math.abs(altitude), 1];
    gps[piexif.GPSIFD.GPSDateStamp] = new Date().toISOString().split('T')[0];
    gps[piexif.GPSIFD.GPSTimeStamp] = new Date().toTimeString().split(' ')[0].split(':').map(Number);

    var exifObj = { "0th": zeroth, "Exif": exif, "GPS": gps };
    return exifObj;
}

export function getExifData(file, parseXMPMetadata, convertDMSToDD) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const base64String = e.target.result.split(",")[1];

            try {
                const exifObj = piexif.load("data:image/jpeg;base64," + base64String);
                const allTags = {
                    ...exifObj["0th"],
                    ...exifObj["Exif"],
                    ...exifObj["GPS"],
                    ...exifObj["1st"],
                };
                const xmpData = parseXMPMetadata(base64String);

                const lat = allTags[piexif.GPSIFD.GPSLatitude] ? convertDMSToDD(allTags[piexif.GPSIFD.GPSLatitude], allTags[piexif.GPSIFD.GPSLatitudeRef]) : null;
                const lon = allTags[piexif.GPSIFD.GPSLongitude] ? convertDMSToDD(allTags[piexif.GPSIFD.GPSLongitude], allTags[piexif.GPSIFD.GPSLongitudeRef]) : null;
                const alt = xmpData.relativeAltitude;
                const datetime = allTags[piexif.ExifIFD.DateTimeOriginal];
                const yaw = xmpData.yaw;
                const width = allTags[piexif.ExifIFD.PixelXDimension];
                const height = allTags[piexif.ExifIFD.PixelYDimension];
                const fov = 58.9; // while shooting video

                resolve({
                    latitude: lat,
                    longitude: lon,
                    altitude: alt,
                    datetime,
                    yaw,
                    width,
                    height,
                    fov
                });
            } catch (error) {
                console.error('piexif error:', error);
                reject(error);
            }
        };
        reader.onerror = (error) => {
            console.error('FileReader error:', error);
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}

export function calculateImageCorners(observation) {
    const center = turf.point([observation.longitude, observation.latitude]);
    const altitude = observation.altitude;
    const diagonalDistance = altitude * Math.tan(observation.fov * Math.PI / 180);
    const distance = diagonalDistance / 2;

    const bearing = (parseFloat(observation.bearing) - 90) % 360;
    const offset = Math.atan(observation.height / observation.width) * 180 / Math.PI;

    const options = { units: 'meters' };
    const topLeft = turf.rhumbDestination(center, distance, (bearing - offset + 180) % 360 - 180, options).geometry.coordinates;
    const topRight = turf.rhumbDestination(center, distance, (bearing + offset + 180) % 360 - 180, options).geometry.coordinates;
    const bottomRight = turf.rhumbDestination(center, distance, (bearing - offset) % 360 - 180, options).geometry.coordinates;
    const bottomLeft = turf.rhumbDestination(center, distance, (bearing + offset) % 360 - 180, options).geometry.coordinates;

    return [distance, topRight, bottomRight, bottomLeft, topLeft];
}
