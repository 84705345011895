import Vue from 'vue'
import VueRouter from 'vue-router'
import Map from '../components/Map.vue'
import FileDrop from '../components/FileDrop.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'fileDrop',
		component: FileDrop
	},
	{
		path: '/map',
		name: 'map',
		component: Map,
		props: route => ({ 
			videoFile: route.params.videoFile, 
			flightLogFile: route.params.flightLogFile,
			imageSequenceFiles: route.params.imageSequenceFiles
		})
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
